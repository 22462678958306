export const getBaseApiUrl = (): string => {
    //return "http://localhost:8080/api";
    //return "https://azure-spring-cloud-interop-cmsi-org-app-registration-ms.azuremicroservices.io/api";
    return "https://asa-cmsi-sys-001-cmsi-org-app-registration-ms.private.azuremicroservices.io/api";
   //return "https://azure-spring-cloud-interop-prd-cmsi-org-app-registration-ms.azuremicroservices.io/api";
   //return "https://asa-cmsi-sys-001-cmsi-org-app-registration-ms.azuremicroservices.io/api";
}

export const getIdMeBaseApiUrl = (): string => {
    // return "http://localhost:8081/api";
     //return "https://azure-spring-cloud-sys-interop-cmsi-idme-integration-ms.azuremicroservices.io/api";
   return "https://asa-cmsi-sys-001-cmsi-idme-integration-ms.private.azuremicroservices.io/api";
   // return "https://azure-spring-cloud-interop-prd-cmsi-idme-integration-ms.azuremicroservices.io/api";
   //return "https://asa-cmsi-sys-001-cmsi-idme-integration-ms.azuremicroservices.io/api";
}

export const getBaseUrl = (): string => {
   // return "http://localhost:3000";

   // main
   // return "https://red-ocean-02af07310.1.azurestaticapps.net";
  
   // sys
    return "https://interoptest-dhhs.nebraska.gov";
 
    // production
   // return "https://interop-dhhs.nebraska.gov";
}

export const getB2CRedirectSignInUrl = (): string => {

    //non- production
      return "https://stateofneb2cdev.b2clogin.com/stateofneb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DHHS_INTEROP_PIPELINE_SIGNUP_SIGNIN&client_id=adeb9e71-23dc-433a-b84f-6dcc963aa6cb&nonce=defaultNonce&redirect_uri=" +
           
             //local
            // "http://localhost:3000" +

           // main server
           // "https://red-ocean-02af07310.1.azurestaticapps.net" +

           //sys server
           "https://interoptest-dhhs.nebraska.gov" +

           "/userLandingHome&scope=openid adeb9e71-23dc-433a-b84f-6dcc963aa6cb&response_type=code";
    
    // for production
     // return "https://stateofneb2c.b2clogin.com/stateofneb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DHHS_INTEROP_PIPELINE_PROD_SIGNUP_SIGNIN&client_id=73fbed97-8f98-492c-9db7-47b4c6b3bb8f&nonce=defaultNonce&redirect_uri=" +
            // "https://interop-dhhs.nebraska.gov" +
          // "/userLandingHome&&scope=openid 73fbed97-8f98-492c-9db7-47b4c6b3bb8f&response_type=code";
}
export const getB2CRedirectLogoutUrl = (): string => {
//non- production
   return "https://stateofneb2cdev.b2clogin.com/stateofneb2cdev.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_DHHS_INTEROP_PIPELINE_SIGNUP_SIGNIN&post_logout_redirect_uri=" +
       
            //local
            // "http://localhost:3000";

           // main server
           // "https://red-ocean-02af07310.1.azurestaticapps.net";

           //sys server
           "https://interoptest-dhhs.nebraska.gov";


// for production
 //return "https://stateofneb2c.b2clogin.com/stateofneb2c.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_DHHS_INTEROP_PIPELINE_PROD_SIGNUP_SIGNIN&post_logout_redirect_uri=" +
        // "https://interop-dhhs.nebraska.gov" ;
}


export const getMemberLookupUrl = (): string => {
    // non -production
     return "https://dhhs-sys-api.ne.gov/dhhs/dhhs-ne/api/member-lookup-mmis/1.0/memberMedicaidID";

    //production
    //return "https://dhhs-api.ne.gov/dhhs/dhhs-ne/api/member-lookup-mmis/1.0/memberMedicaidID";

}

export const getMemberLookupClientId = (): string => {
    // non -production
     return "776ded183cb5104891db3adc61df738f";

    //production
   // return "2658ed42af1d0b816c23c9682be951c2";

}

export const getMemberLookupClientSecret = (): string => {
    // non -production
     return "0206f3e1af485a5e430754edf860af8e";

    //production
   // return "3d0c947aafed3d53df90a186a2356af7";


}



export const getIdMeAuthorizeUrl = (): string => {

    // non -production
     return "https://api.idmelabs.com/oauth/authorize";

    //production
   //return "https://api.id.me/oauth/authorize";
}

export const getIdMeAuthorizeClientId = (): string => {
    // non -production
    return "c1102c03087013960ed2c75ae47f7920";

   //production  
   // return "1c6f9eb8ce2393cb87a734e031ce6ed2";
}

export const getAdRedirectUrl = (): string => {

   // local
  //  return "http://localhost:3000";

   // non -production
   return "https://interoptest-dhhs.nebraska.gov";

  //production  
//   return "https://interop-dhhs.nebraska.gov";
}

export const getAdAuthority = (): string => {

   // local
   return "https://login.microsoftonline.com/stateofne.onmicrosoft.com";

   // non -production
   // return "https://login.microsoftonline.com/stateofne.onmicrosoft.com";

  //production  
//   return "https://login.microsoftonline.com/stateofne.onmicrosoft.com";
}

export const getAdAppClientId = (): string => {

   // local,dev
  //  return "9f62b07d-f3dd-4284-be24-59292a9b9d74";

   // non -production
   return "2cd4540a-7363-482b-8443-0152dd0da819";

  //production  
//   return "551b26d3-c8c1-4737-b747-ed3493820fc3";
}

export const getPatientEndpoint = (): string => {

   // local,dev
  //  return "http://localhost:3000/dhhs/trading-partner/api/cmsi/patient-access-api/1.0.0";

   // non -production
   return "https://dhhs-uat-api.ne.gov/dhhs/trading-partner/api/cmsi/patient-access-api/1.0.0";

  //production  
//   return "https://dhhs-api.ne.gov/dhhs/trading-partner/api/cmsi/patient-access-api/1.0.0";
}

export const getProviderEndpoint = (): string => {

   // local,dev
  //  return "http://localhost:3000/dhhs/trading-partner/api/cmsi/provider/1.0.0";

   // non -production
   return "https://dhhs-uat-api.ne.gov/dhhs/trading-partner/api/cmsi/provider/1.0.0";

  //production  
//   return "https://dhhs-api.ne.gov/dhhs/trading-partner/api/cmsi/provider/1.0.0";
}

// export const getApplicationScope =() =>{
//    return"openid profile https://stateofneb2cdev.onmicrosoft.com/adeb9e71-23dc-433a-b84f-6dcc963aa6cb/patient.*.read ";
// }

// export const getApplicationStatusClientSecretKey =() =>{
//    return "OVJ8Q~7Z0iDovjBwkxp3vVTKF1UlYklYcQ39cdo2";
// }

// export const getApplicationStatusClientID =() =>{
//    return "adeb9e71-23dc-433a-b84f-6dcc963aa6cb";
// }

// export const getApplicationAccessTokenUrl =() =>{
//    return "https://stateofneb2cdev.b2clogin.com/stateofneb2cdev.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1a_dhhs_interop_pipeline_signup_signin";
// }

// export const getApplicationAuthUrl =() =>{
//    return "https://stateofneb2cdev.b2clogin.com/stateofneb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_dhhs_interop_pipeline_signup_signin";
// }

// export const getApplicationTokenName =() =>{
//    return "CMSI";
// }

// export const getApplicationGrantType =() =>{
//    return "authorization_code";
// }

// export const getApplicationClientAuthentication =() =>{
//    return "authorization_code";
// }
// export const getApplicationRedirectURL =() =>{
//    return "http://localhost:3000";
// }

// export const getApplicationState =() =>{
//    return "123456";
// }

// export const getApplicationTokenType =() =>{
//    return "Bearer";
// }

// export const getApplicationChallengeAlgorithm =() =>{
//    return "S256";
// }

// export const getApplicationUseBrowser =() =>{
//    return "false";
// }

// export const getApplicationPassword =() =>{
//    return "Chanq321thorn!";
// }

// export const getApplicationUsername =() =>{
//    return "ACEJrsUser";
// }

// export const getApplicationAddTokenTo =() =>{
//    return "header";
// }

// export const getNewOauth2Array = () => {
//    const oauth2Array = 
//       [
//          {
//              "key": "scope",
//              "value": getApplicationScope(),
//              "type": "string"
//          },
//          {
//              "key": "clientSecret",
//              "value": getApplicationStatusClientSecretKey(),
//              "type": "string"
//          },
//          {
//              "key": "clientId",
//              "value": getApplicationStatusClientID(),
//              "type": "string"
//          },
//          {
//              "key": "accessTokenUrl",
//              "value": getApplicationAccessTokenUrl(),
//              "type": "string"
//          },
//          {
//              "key": "authUrl",
//              "value": getApplicationAuthUrl(),
//              "type": "string"
//          },
//          {
//              "key": "tokenName",
//              "value": getApplicationTokenName(),
//              "type": "string"
//          },
//          {
//              "key": "grant_type",
//              "value": getApplicationGrantType(),
//              "type": "string"
//          },
//          {
//              "key": "client_authentication",
//              "value": getApplicationClientAuthentication(),
//              "type": "string"
//          },
//          {
//              "key": "redirect_uri",
//              "value": getApplicationRedirectURL(),
//              "type": "string"
//          },
//          {
//              "key": "state",
//              "value": getApplicationState(),
//              "type": "string"
//          },
//          {
//              "key": "tokenType",
//              "value": getApplicationTokenType(),
//              "type": "string"
//          },
//          {
//              "key": "challengeAlgorithm",
//              "value": getApplicationChallengeAlgorithm(),
//              "type": "string"
//          },
//          {
//              "key": "useBrowser",
//              "value": getApplicationUseBrowser(),
//              "type": "boolean"
//          },
//          {
//              "key": "password",
//              "value": getApplicationPassword(),
//              "type": "string"
//          },
//          {
//              "key": "username",
//              "value": getApplicationUsername(),
//              "type": "string"
//          },
//          {
//              "key": "addTokenTo",
//              "value": getApplicationAddTokenTo(),
//              "type": "string"
//          }
//      ]
//      return oauth2Array
//    }
   